import { SelectedProduct } from "@/components/Product/Types";
import { OrderPreviewInfo } from "@/components/Product/Types";
import formatNumber from "@/components/Hooks/formatNumber";

const toOrderPreviews = (products: SelectedProduct[]): OrderPreviewInfo[] => {
  const orderPreviews = products.map((product) => {
    // const { baseOptions, additionalOptions } = product.options;
    const baseOptionNames = product.options.map((option) => {
      const { name, addPrice } = option;
      if (addPrice > 0) {
        return `${name}(+${formatNumber(addPrice)})`;
      } else {
        return name;
      }
    });

    // TODO: 추가 옵션 설정 가능할 때 추가
    // const additionalOptionNames = additionalOptions.map(
    //   (option) => option.name
    // );

    return {
      productName: product.productName,
      price: product.price,
      thumbnailImg: product.thumbnailImg,
      quantity: product.qty,
      selectedOptions: [...baseOptionNames],
      marketPrice: product.marketPrice,
    };
  });

  return orderPreviews;
};

export default toOrderPreviews;
