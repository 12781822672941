






















































































































































































































import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
} from "@vue/composition-api";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import moment from "moment";

import formatNumber from "@/components/Hooks/formatNumber";
import toOrderPreviews from "@/components/Order/Hooks/toOrderPreviews";

import { Shop } from "@/components/Shop/Types";

import BaseLayout from "@/components/Layout/BaseLayout.vue";
import MyshopInfoLayout from "@/components/Layout/MyshopInfoLayout.vue";
import SmartPhoneLayout from "@/components/Layout/SmartPhoneLayout.vue";
import CloseIcon from "@/assets/ElementsImage/CloseIcon2.vue";
import UpdownArrow from "@/components/Common/UpDownArrow.vue";
import OrderPreview from "@/components/Common/OrderPreview.vue";
import AutoDestroyPopup from "@/components/Common/AutoDestroyPopup.vue";
import Lottie from "vue-lottie";
import ChannelTalk from "@/components/ChannelTalk/ChannelTalk.vue";

import checkAnimationData from "@/assets/Lottie/CheckAnimation.json";

export default defineComponent({
  components: {
    BaseLayout,
    MyshopInfoLayout,
    SmartPhoneLayout,
    CloseIcon,
    UpdownArrow,
    OrderPreview,
    AutoDestroyPopup,
    Lottie,
    ChannelTalk,
  },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
    orderNo: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const paymentInfo = ref();
    const productList = ref([]);
    const shippingInfo = reactive({
      address: "",
      addressDetail: "",
      message: "",
      phone: "",
      recipientName: "",
      personalCustomsCode: null,
    });
    const orderResult = ref();
    const payList = ref<
      { payType: string; payContent: string; amount: number }[]
    >([]);
    const totalAmount = ref(0);
    const totalShippingFee = ref(0);
    const hasRecruitmentProduct = computed(
      () => orderResult.value?.hasRecruitmentProduct ?? false
    );

    try {
      const params = context.root.$route.params;
      orderResult.value = JSON.parse(params.orderResult);
      payList.value = orderResult.value.payList;

      paymentInfo.value = payList.value.find(
        (payment: any) =>
          payment.payType === "ACCOUNT" ||
          payment.payType === "CARD" ||
          payment.payType === "CELLPHONE" ||
          payment.payType === "NAVERPAY_AUTH"
      );

      totalAmount.value = orderResult.value.totalAmount;
      totalShippingFee.value = orderResult.value.totalShippingFee;
      if (orderResult.value.orderList.length > 0) {
        shippingInfo.address = orderResult.value.orderList[0].address;
        shippingInfo.addressDetail =
          orderResult.value.orderList[0].addressDetail ?? "";
        shippingInfo.phone = orderResult.value.orderList[0].phone;
        shippingInfo.recipientName =
          orderResult.value.orderList[0].recipientName;
        shippingInfo.message = orderResult.value.orderList[0].message ?? "";
        productList.value = orderResult.value.orderList.map((order: any) => {
          const options = order.option.map((cur: any) => {
            return {
              addPrice: cur.addPrice,
              type: "CHOICE",
              name: cur.content,
            };
          });

          return {
            brandName: order.brandName,
            price: order.amount + order.vat,
            productName: order.productName,
            thumbnailImg: order.productThumbnailUrl,
            qty: order.qty,
            options: options,
          };
        });

        const matchedOrder = orderResult.value.orderList.find(
          (order: any) => order.personalCustomsId != null
        );

        shippingInfo.personalCustomsCode =
          matchedOrder?.personalCustomsId ?? null;
      }
    } catch {
      alert("만료된 페이지입니다.");
      context.root.$router.replace({
        name: "Myshop",
        params: {
          shopUrl: props.shopInfo.urlPath,
        },
      });
    }

    const paymentMethodText = computed(() => {
      if (!paymentInfo.value) return "";

      switch (paymentInfo.value.payType) {
        case "ACCOUNT":
          return "무통장입금";
        case "CARD":
          return `${
            paymentInfo.value.payContent ? paymentInfo.value.payContent : ""
          }카드`;
        case "CELLPHONE":
          return "휴대폰결제";
        case "NAVERPAY_AUTH":
          return "네이버페이";
        default:
          return "";
      }
    });

    const isProductListOpened = ref(false);

    const orderPreviews = computed(() => {
      if (!productList.value) return [];
      const orderPreviews = toOrderPreviews(productList.value);

      return orderPreviews;
    });

    const isCopyPopupVisible = ref(false);

    const clickCopyBtnHandler = async () => {
      try {
        await context.root.$copyText(props.orderNo);
        isCopyPopupVisible.value = true;
      } catch {
        alert("복사 실패");
      }
    };

    const checkAnimationIcon = ref();

    const checkAnimationOptions = reactive({
      animationData: checkAnimationData,
      loop: false,
    });

    const handleCheckAnimation = (anime: any) => {
      checkAnimationIcon.value = anime;
    };

    const requestBankInfo = async () => {
      const res = await axios.get(
        `${process.env.VUE_APP_BACKEND_SERVER}/info/bank/`
      );
      return camelCaseKeys(res.data, { deep: true });
    };

    const bankInfo = ref();

    onMounted(async () => {
      if (!payList.value.some((payment) => payment.payType === "ACCOUNT")) {
        return;
      }

      try {
        const res = await requestBankInfo();
        bankInfo.value = res;
      } catch {
        alert("은행 정보를 불러오지 못 했습니다.");
      }
    });

    const locale = context.root.$i18n.locale;

    const deadline = computed(() => {
      const duration = hasRecruitmentProduct.value ? 1 : 3;
      const unit = hasRecruitmentProduct.value ? "hours" : "days";
      const formatPattern = hasRecruitmentProduct.value
        ? "YYYY.MM.DD HH시mm분"
        : "YYYY.MM.DD";

      const createdAt = moment().locale(locale);
      const deadline = createdAt.add(duration, unit).format(formatPattern);

      return deadline;
    });

    return {
      paymentInfo,
      productList,
      shippingInfo,
      orderResult,
      totalAmount,
      totalShippingFee,
      paymentMethodText,
      isProductListOpened,
      orderPreviews,
      isCopyPopupVisible,
      checkAnimationOptions,
      checkAnimationIcon,
      bankInfo,
      deadline,
      formatNumber,
      clickCopyBtnHandler,
      handleCheckAnimation,
      hasRecruitmentProduct,
    };
  },
});
